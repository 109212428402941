import React from "react"
import styled from 'styled-components';
import noImage from '../images/noImage.svg';

import Avatar from '@material-ui/core/Avatar'

const TitleContainer = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
text-align: center;
justify-content: center;

`
const Logo = styled.img`
width: 125px;
height: 125px;
`
const Flex = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const Pad = styled.div`

margin-right: 10px;`

const TitleWrapper = styled.div`
margin: 0 30px;
`

const Header = ({ title, className }) => {
    return (
        <header className={className}>
            <TitleContainer className='title-container'>
                <Logo src={noImage} alt="Ui Roundup Logo" />
                <TitleWrapper>
                    <h1 className='title'>
                        {title}
                    </h1>
                    <div>A Curated List of Bookmarks by</div>
                    <Flex>

                        <Pad><a href="https://stevebullis.com">Steve Bullis</a></Pad>
                        <a href="https://stevebullis.com"><Avatar alt="Steve Bullis" src="https://stevebullis.com/images/meinplaid.jpg" /></a>
                    </Flex>
                </TitleWrapper>

            </TitleContainer>
        </header>
    )
}

export default Header