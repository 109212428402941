import React, { useState } from 'react'
import autoTagger from 'auto-tagger';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import readingTime from 'reading-time'

import MaterialCard from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import IconButton from '@material-ui/core/IconButton';

import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import BreakUrl from './breakUrl';
import Image from './image';


const ItemContainer = styled(MaterialCard)`
/* border: 1px solid #ccc;
border-radius: 5px; */
`

const ItemBody = styled.div`
padding: 15px;
`

const LinkHeading = styled.h2`
  font-size: 18px;
`
const LinkImage = styled(Image)`
  max-width: 100%;
`
const ImageContainer = styled(CardMedia)`
  /* width: 100%;
  text-align: center;
  min-height: 100px; */
`

const TagItem = styled(Chip)`
text-transform: capitalize;
`

const TagContainer = styled.ul`
display: flex;
justify-content: left;
flex-wrap: wrap;
list-style: none;

margin: 10px 0;
padding: 10px;

& > li {
    margin: 2px;
}

`
const Link = styled.a`
text-decoration: none;
color: #333;
&:hover,
&:visited {
    color: #333;
}
`

const BackContainer = styled.div`
 overflow-y: scroll;
 max-height: 100%;
`

const StyledCardActions = styled(CardActions)`
justify-content: flex-end;

`
const Card = ({ url, image, pageTitle, description, txtBody = "", tags = [], _id, _ts }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    let generatedTags = tags;
    if (!tags || tags.length < 1) {
        generatedTags = [
            ...(autoTagger.useStopWords('en').fromText(description, 2, 3) || [])
            ,
            ...(autoTagger.useStopWords('en').fromText(pageTitle, 2, 3) || []),
            ...(autoTagger.useStopWords('en').fromText(txtBody, 2, 3) || [])
        ]
    }

    const sortedTags = generatedTags.sort((a, b) => b.count - a.count).filter(tag => tag.word.length > 1);
    const firstLetter = url.replace('www.', '').replace('https://', '').replace('http://', '')[0].toUpperCase();
    const domain = (new URL(url))
    const date = new Date(_ts / 1000).toLocaleDateString();

    const reading = txtBody && txtBody.length ? readingTime(txtBody).text : '';
    const adjustedTags = sortedTags.length > 10 ? sortedTags.splice(0, 9) : sortedTags;
    
    
    return (
        <ItemContainer key={url}>
            <CardHeader
                avatar={
                    <Avatar aria-label={firstLetter}>
                        {firstLetter}
                    </Avatar>
                }

                title={<Link href={domain.href + domain.hostname}>{domain.hostname}</Link>}
                subheader={reading}
            />
            <Flippy
                isFlipped={isFlipped}
                flipOnClick={false}
                flipDirection="horizontal" // horizontal or vertical

            >
                <FrontSide

                >


                    <ImageContainer>
                        <LinkImage src={image} />
                    </ImageContainer>
                    <ItemBody>
                        <LinkHeading>{pageTitle || <BreakUrl url={url} />}</LinkHeading>
                        <small><a href={url} target="_new"><BreakUrl url={url} /></a></small>
                        {adjustedTags.length ?
                            <TagContainer component="ul">

                                {adjustedTags.map(tag => <li key={tag.word}><TagItem label={tag.word} /></li>)}

                            </TagContainer> : null}

                        <div>{description}</div>


                    </ItemBody>
                </FrontSide>
                <BackSide>
                    <BackContainer>
                        {txtBody}
                    </BackContainer>

                </BackSide>
            </Flippy>
            {showInfo && <ItemBody>
                <div>ID: {_id}</div>
                <div>Date added: {date}</div>
            </ItemBody>}
           
            <StyledCardActions > 
                <div style={{ flexGrow: 1, color: '#ccc' }}>
                    <IconButton
                        onClick={() => setShowInfo(!showInfo)}
                        aria-label={_id}
                    >
                        <InfoIcon alt={_id} />
                    </IconButton>
                    {txtBody && txtBody.length && <IconButton
                        className={''}
                        onClick={() => setIsFlipped(!isFlipped)}
                        aria-label="Text Preview"
                    >
                        <ChromeReaderModeIcon alt="Text Preview" />
                    </IconButton>}
                </div>

                <IconButton
                    as="a" href={url} target="_new" color="primary"
                    className={''}
                    aria-label="Visit"
                >
                    <LinkIcon alt="Visit" />
                </IconButton>




            </StyledCardActions>

        </ItemContainer>)
}

export default Card;