import React from 'react';
import _ from "lodash";
import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import { graphql } from "gatsby"
import styled from 'styled-components'
import Masonry from 'react-masonry-css'

import Search from '../components/search';
import Layout from "../components/layout"
import BreakUrl from '../components/breakUrl';
import Card from '../components/card';


const MasonryWrapper = styled.div`
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}`


const IndexPage = (props) => {
  console.log(props)
  const links = props.data.links.all_links.data
  const sortedLinks = links.sort((a,b)=>(a._ts - b._ts)).reverse().map(link =>{
    const explode = (obj, key)=>{
      const newObj = obj;
      try {
        const newKey = JSON.parse(obj[key]);  
        Object.assign(newObj, {[key]: newKey})
      } catch (error) {
        
      }
      return newObj;
    }
    return Object.assign(link, explode(link, 'tags'), explode(link, 'headings'), explode(link, 'images'), explode(link, 'links'),)
  });



  const generateLinks = (linkArr) => linkArr.map((link) => (
    <Card {...link} key={link._id}/>
  ));


  const breakpointColumnsObj = {
    default: 5,
    1600: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  return (
    <Layout>
      <MasonryWrapper>
      <Search data={sortedLinks} render={
        (data)=> (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
            {generateLinks(data)}

        </Masonry>)
      } />
      </MasonryWrapper>
    </Layout>
  );
}




export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
  links {
    all_links(_size: 10000) {
      data{
			url
      pageTitle
      image
      txtBody
      description
      title
      tags
      _id
      _ts
      }
    }
  }
}
`;


