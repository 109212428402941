/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import CssBaseline from '@material-ui/core/CssBaseline';
import styled from 'styled-components';

import "@fontsource/roboto/index.css";

const StyledMain = styled.div`
padding: 5px 15px;
background-color: #ddd;

`
const StyledHeader = styled(Header)`
color: #222;
`
const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)

    return (
        <React.Fragment>
            <CssBaseline />
            <StyledMain>
            <StyledHeader title={data.site.siteMetadata.title}>
            </StyledHeader>

                {children}
            </StyledMain>
        </React.Fragment>
    )
}



export default Layout