import React from 'react';

const BreakUrl = ({ url = "" }) => {
    const split = url.replace('https://', '').replace('http://', '').split('/')

    const replaceSlashes = (item, index) => item.length > 1 ?
        <span key={index}>{item}<wbr /><span>/</span></span>
        : '';

    return split.map(replaceSlashes)
}

export default BreakUrl;