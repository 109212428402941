import React from "react";
import noImage from '../images/noImage.svg';

class ImageWithStatusText extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageStatus: true };
    }

    handleImageLoaded() {
        this.setState({ imageStatus: true });
    }

    handleImageErrored() {
        this.setState({ imageStatus: false });
    }

    render() {
        if(this.state.imageStatus){
            return (
                <img
                    className={this.props.className}
                    src={this.props.src}
                    onLoad={this.handleImageLoaded.bind(this)}
                    onError={this.handleImageErrored.bind(this)}
                />
            )
        }

        return <img
        className={this.props.className}
        src={noImage}
    />;
    }
}
export default ImageWithStatusText;