import React, { useEffect, useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { Formik, Form, Field, useFormik } from 'formik'
import { graphql, StaticQuery } from 'gatsby'
import _ from 'lodash';
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MaterialButton from '@material-ui/core/Button';



const SearchContainer = styled(Paper)`
margin: 20px 0;
padding:15px 0;
text-align: center;
`

const StyledTextField = styled(TextField)`
width: 95%;
`

const SearchComponent = ({ render, data = [], storeData }) => {
    const [text, setText] = useState('');
    const results = useFlexSearch(text, storeData.localSearchPages.index, storeData.localSearchPages.store)
    const [renderResults, setRenderResults]= useState(null);
    const selectedResults = new Set(results.map(({ _id }) => _id))
    const filteredResults = data.filter(item => selectedResults.has(item._id));

    
    useEffect(()=>{
        setRenderResults(render(text ? filteredResults : data));
    }, [text]);




    return (
        <>
            <SearchContainer elevation={0}>
                    <StyledTextField value={text} name="query" onChange={(evt) => setText(evt.target.value)} label="Search" style={{ flexGrow: 1, margin: '0 15px' }} />


            </SearchContainer>

            {renderResults}            
        </>
    );
}


const SearchBar = ({ render, data, index }) => {
    return (
        <StaticQuery
            query={graphql`
            query SearchQuery {
                localSearchPages 
                 {
                    index
                    store
                }
            }
          `}
            render={storeData => {
                return <SearchComponent storeData={storeData} render={render} data={data} />
            }}
        />

    )
}

export default SearchBar;